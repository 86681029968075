@import '../../../../global';

.genre-tabs {
    display: flex;
    align-items: center;
    color: #fff;
    justify-content: center;
    padding: 2rem 20px;
    overflow-x: scroll;

    .active-genre-tab {
        background: $kg_color;
        border-color: $kg_color;
        color: #000;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    li {
        border: 1px solid #6C6969;
        border-radius: 9px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: .8rem;
        gap: 10px;
        margin: 10px;
        cursor: pointer;
        transition: .1s linear;
        color: #6C6969;
        font-weight: 700;
        user-select: none;

        &:hover {
            background: #fff;
            color: #000;
        }
    }
}

@media screen and (max-width:480px) {
    .genre-tabs {
        justify-content: flex-start;
    }
}