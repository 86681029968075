@import "../../../global";

.episodes {
    .bg-img-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    a {
        text-decoration: none;
    }

    .overlay {
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(21, 5, 34, 0.9);
    }

    // .bg-img-wrapper::after {
    //     background: rgba(21, 5, 34, 0.59);
    //     background: red;
    // }
}

.seasons-controls {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        background: rgba(254, 254, 254, 0.15);
        padding: 6px;
        border-radius: 999px;
        padding-left: 4px;
        cursor: pointer;
        fill: #ffffff61;

        &:hover {
            fill: #fff;
        }

    }

    .season-text {
        background: linear-gradient(216.27deg, rgba(54, 31, 83, 0.65) -62.47%, rgba(88, 20, 89, 0.65) -1.66%, rgba(24, 1, 24, 0.65) 52.82%);
        border-radius: 15px;
        padding: 10px;
        width: max-content;
        margin: 0 20px;
    }
}



.vertical-slider-card {
    background: $kg_color;
    border-radius: 20px;
    height: 197.27px;
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%;
    max-width: 660px;
    opacity: .4;
    transform: scale(.9);

    .star-wrapper {
        display: flex;
        align-items: center;

        p {
            margin-left: 5px;
        }

        .star-img {
            width: 23px;
            height: 23px;
            border: 1px solid;
        }
    }


    .vertical-slider-content {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 20px;
    }

    .title-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #150522;
    }

    img {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        height: 100%;
        width: 200px;
        object-fit: cover;
    }
}

.active-vertical-slider-in-view {
    opacity: 1;
    margin-bottom: 20px;
    transform: scale(1);
}


.episodes-main {
    display: flex;
    justify-content: space-evenly;
    color: #fff;
    max-width: 1360px;
    padding: 20px;
    margin: auto;
    position: relative;

    .episodes-content {
        flex: 1;
        padding-right: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &>div {
            max-width: 400px;
            text-align: center;
        }

        h1 {
            margin-bottom: 20px;
        }

        .rating-genre {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 0;
        }
    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: center;

        .filled-btn {
            margin-right: 10px;
        }
    }

    .vertical-slider-wrapper {
        flex: 1;
        overflow-y: scroll;
        height: 80vh;
        padding-top: 200px;
        padding-bottom: 500px;
        padding-left: 0;
        padding-right: 0;
        scrollbar-width: 0;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

input[type=range] {
    margin: 0 10px;
    height: 6px;
    border-radius: 100px;
    background: #fff;
    appearance: none;
    width: 560px;
    outline: none;
}

input[type=range]::-moz-range-thumb input[type=range]::-webkit-range-thumb {
    background: #22C55E;
    border: none;
}

@media screen and (max-width:768px) {
    .episodes-content {
        margin-top: 100px;
    }

    .episodes-main {
        .episodes-content {
            padding-right: 0;
        }

        .vertical-slider-wrapper {
            padding-top: 47px;
            padding-bottom: 80px;

            .vertical-slider-card {
                height: max-content;
                opacity: 1;
                margin-bottom: 20px;
                transform: scale(1);

                .title-wrapper {
                    margin-bottom: 13px;
                }

                img {
                    display: none;
                }
            }
        }
    }
}