.movie-cards-grid {
    // column-count: 4;
    // margin-top: 30px;
    padding: 30px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .movie-card {
        margin: 5px;
        width: calc(100%/7);
        transform: scale(.85);
    }
}

@media screen and (max-width:768px) {
    .movie-cards-grid {
        .movie-card {
            width: calc(100%/2);
            margin: 0;
        }
    }
}