@import "../../../_global.scss";

.details-movie {
  background: linear-gradient(90deg, rgba(26, 5, 43, 1), rgba(26, 5, 43, 0.9)),
    url("https://images.plex.tv/photo?size=medium-360&scale=1&url=https%3A%2F%2Fimage.tmdb.org%2Ft%2Fp%2Foriginal%2FwL91L05ONOOv5965sTxWVYbJGZZ.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
  background-attachment: fixed;
  padding-top: 2rem;
  min-height: 80vh;
  
  .details-page-banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .overlay {
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(21, 5, 34, 0.9)
    }
  }

  .container {
    position: relative;

    .image-movie-details {
      display: flex;
      
      .image {
        flex: 3;

        img {
          height: auto;
          max-width: 100%;
          border: 4px solid white;
        }
      }

      .movie-details {
        padding: 0rem 2rem;
        flex: 9;
        color: $tertiary_color;
        @media screen and (max-width: 768px) {
         margin-top: 1rem;
        }
        .title {
          font-size: 2.2rem;
          color: $kg_color;
          font-weight: normal;
          @media screen and (max-width: 768px) {
            font-size: 1.7rem !important;
          }
          svg {
            fill: green;
          }
        }

        .year-calender svg {}

        .age-year-rating {
          margin-top: 1rem;
          flex-wrap: wrap;
          display: flex;
          gap: 0.8rem;
         
          @media screen and (max-width: 768px) {
            font-size: 0.9rem;
          
            column-gap: 0;
          }
          @media screen and (max-width: 500px) {
            font-size: 0.5rem;
            
          }
          .age {
            background: black;
            display: flex;
            align-items: center;
            padding: 0.2rem 0.5rem;
            // margin-right: 1rem;
          }

          .rating svg,
          .restrictions svg {
            position: relative;
            margin-right: 0.3rem;
            // top: 2px;
            fill: $kg_color;
          }
        }

        .watch-trailer-share {
          margin-top: 1.5rem;
          flex-wrap: wrap;
          display: flex;

          .watch,
          .others {
            background-color: $kg_color;
            padding: 0.2rem 0.5rem;
            text-decoration: none;
            font-size: $normal_font;
            border-radius: 5px;
            color: $primary_color;
            font-weight: 400;
            margin-right: 1rem;
            cursor: pointer;
          }

          .watch:hover {
            background-color: $tertiary_color;
          }

          .others {
            background: black;
            color: white;
          }

          .others:hover {
            background-color: $tertiary_color;
            color: $primary_color;
          }
        }

        // .description {
        //   margin-top: 3rem;
        //   width: 60%;
        //   font-size: $normal_font;
        //   word-spacing: 0.2rem;
        //   line-height: 1.8rem;
        // }
        .description {
          margin-top: 3rem;
          position: relative;
          width: 60%;
          font-size: $normal_font;
          word-spacing: 0.2rem;
          line-height: 1.8rem;
          overflow: hidden;
         @media screen and (max-width: 768px) {

          margin-top: 1rem;
         }
          .description-text {
            display: -webkit-box;
            -webkit-line-clamp: 3; // Limit description to 3 lines
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        
            &.show-full {
              -webkit-line-clamp: unset; // Expand description when show-full is active
            }
          }
        
          .toggle-description {
            margin-top: 0.5rem;
            cursor: pointer;
            color: $kg_color;
            font-weight: 600;
        
            &:hover {
              color: $tertiary_color;
            }
          }
        }
        
        @media only screen and (max-width: 1024px) {
          .description {
            width: 100% !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .description {
    width: 100% !important;
  }
}

@media only screen and (max-width: 650px) {
  .image-movie-details {
    flex-direction: column;

    .image {
      img {
        width: 50%;
        display: block;
        margin: 0 auto;
      }
    }

    .description {
      width: 100% !important;
    }

    .watch-trailer-share {
      flex-direction: column;

      a {
        margin-top: 1rem;
      }
    }

    .movie-details {
      margin-top: 2rem;
      font-size: 1rem !important;
      margin-bottom: 5rem;
    }

    .age {
      margin-right: 1rem !important;
    }
  }
}