@import "../../../_global.scss";

.banner {
  min-height: 80vh;
  width: 100%;
  // background: linear-gradient(rgba(26, 5, 43, 0.58), rgba(26, 5, 43, 0.58)),
  //   url("https://user-images.githubusercontent.com/67514352/186646869-f6b7dcf6-c8cc-44aa-8853-9379d308de1f.png");
  background-color: black;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  .landing-player {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: -70px;
    // z-index: 2;
    opacity: 0.6;
    // height: 10px;
  }

  h1 {
    color: $tertiary_color;
    font-size: $banner_text;
    font-weight: $banner_font_weight;
  }

  .inside-banner {
    border-radius: $border_rad;
    background: rgba(26, 5, 43, 0.72);
    padding: 3rem;
    // z-index: 1000;
    text-align: center;
  }

  .yellow-span {
    color: $kg_color;
  }

  .play {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    gap: 0.5rem;
    font-size: $normal_font;
    cursor: pointer;

    p {
      text-align: center;
      color: white;
    }
  }
}


.container {
  z-index: 9999;
}

.banner .inside-banner {
  background: transparent;
  max-width: 900px;

  p {
    color: #fff;
    margin-bottom: -23px;
    margin-top: 48px;
    font-size: 1.3rem;
  }
}

.input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 470px;
  margin: auto;
  margin-top: 50px;

  input,
  button {
    padding: 10px;
    font-weight: medium;
    outline: none;
  }

  input {
    width: 100%;
    border: 2px solid #fff;

    &::placeholder {
      color: #000;
    }
  }

  button {
    width: 100%;
    max-width: 100px;
    background: $kg_color;
    border: 2px solid $kg_color;
    cursor: pointer;
    margin-left: 2px;
  }
}

.banner {
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    background: linear-gradient(26.76deg, rgba(21, 5, 34, 0.811)57.85%, rgba(21, 5, 34, 0.3102) 109.37%);
  }
}

@media only screen and (max-width: 650px) {
  h1 {
    font-size: $m_banner_text !important;
    font-weight: $banner_font_weight;
    text-align: center;
  }

  .inside-banner {
    padding: 1.5rem !important;
  }

  .play {
    font-size: $m_normal_font !important;
  }
}