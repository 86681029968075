@import '../../../global';

.search-main {
    max-width: 1460px;
    margin: auto;

    .no-results-text {
        margin-top: 50px;
        border-bottom: 2px solid $kg_color;
        padding-bottom: 3px;
    }

    .movie-cards-grid {
        // column-count: 4;
        // margin-top: 30px;
        padding: 30px 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        .movie-card {
            margin: 5px;
            width: calc(100%/6);
            transform: scale(.85);
        }
    }
}

@media screen and (max-width: 768px) {
    .search-main .movie-cards-grid .movie-card {
        width: calc(100%/2.2);
    }

    .no-results-text {
        padding: 20px;
        margin-bottom: 15px;
    }

    .movie-box {
        width: 100%;
    }
}