@import "../../../_global.scss";

nav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 1000;

  a {
    white-space: nowrap;
    color: #fff;
    font-weight: 500;
    text-transform:capitalize;
    &:hover,
    .dropdown-toggler:hover {
      color: $kg_color;
      transition: all ease 1s;
    }
  }

  .active-link {
    color: $kg_color;
  }
}

.chevron-icon {
  margin-right: 20px;
}

.menu-btn {
  display: none;
}

.header {
  padding: 1rem;
 
    position: sticky;
    top: 0;
    z-index: 1000000; // Ensure the header is above other content
    background-color: $primary_color; // Ensure the background color is consistent with your design
    width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); // Optional: Add a subtle shadow for a floating effect


  // position: relative;
  // height: 600px;


  #logo img {
    width: 130px
  }

  .header-left-content {
    display: flex;
    align-items: center;
  }

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;


  }

  button,
  .nav-link {
    outline: none;
    text-decoration: none;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border: none;
    font-size: $normal_font;
    white-space: nowrap;
    cursor: pointer;
  }

  .right-content {
    display: flex;
    align-items: center;
    color: $kg_color;

    .search-wrapper {
      display: flex;
      align-items: center;

      input {
        position: relative;
        // top: -8px;
        margin: 0 10px;
        margin-left: 0;
        // margin-left: -100px;
        border: 2px solid $kg_color ;
        background: transparent;
        padding: 3px;
        border-radius: 5px;
        color: $kg_color;
        // width: 100%;
        outline: none;
      }
    }

    .search-icon {
      margin-top: 5px;
      margin-right: 10px !important;
    }
  }

  .profile-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: .2s linear;

    .profile-icon {
      width: 35px;
      height: 35px;
      margin-right: 20px;
    }

    &:hover {
      opacity: .8;
    }

    p {
      margin-right: 3px;
    }
  }

  .sign-in-up {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    @media screen and (max-width: 490px) {
      gap: 0.5rem;
    }
    div {
      display: flex;

      p {
        margin-top: 3px;
        font-size: 1em;
        text-wrap: nowrap;
        white-space: nowrap;
        
        @media screen and (max-width: 768px) {
          font-size: 0.8em;
        }

        @media screen and (max-width: 490px) {
          font-size: 0.6em;
        }
        @media screen and (max-width: 400px) {
          font-size: 0.5em;
        }
      }
    }

    img {
      position: relative;
      margin-right: 5px;
      width: 20px;
      height: 20px;
    }

    // .sign-in {
    //   background-color: $primary_color;
    //   color: #fff;
    //   transition: 0.4s all;

    //   &:hover {
    //     color: $tertiary_color;
    //   }
    // }

    .sign-up,
.sign-in {
  background-color: $kg_color;
  color: $primary_color;
  border-radius: 2px;
  transition: 0.4s all;
  text-decoration: none;
  padding: 0.5em 1em;
  display: flex;
  justify-content: center;
 
  /* Add min and max width for responsiveness */

  width: 100%; /* Ensures button width scales according to container */
  @media screen and (max-width: 768px) {
    padding: 0.4em 0.5em !important;
  }
  @media screen and (max-width: 490px) {
    font-weight: 700;
  }
  

  &:hover {
    background-color: $tertiary_color;
  }

  
}

    .access-packs {
      display: flex;
      justify-content: center;
      border: 2px solid $kg_color ;
      color: $kg_color;
      border-radius: 5px;
      transition: 0.4s all;
      padding: 0.5rem 1rem 0.5rem 1rem;
      @media screen and (max-width: 768px) {
        padding: 0.5em 0.5em !important;
      }

      @media screen and (max-width: 490px) {
        padding: 0.3em 0.3em !important;
        font-weight: 700;
      }
      &:hover {
        border-color: $tertiary_color;
        color: $tertiary_color;
      }
    }
  }
}

.account-dropdown {
  background: #000;
  padding: 10px;
  border-radius: 8px;
  max-width: 300px;
  position: absolute;
  right: 20px;
}

.dropdown {
  position: relative;

  .dropdown-toggler {
    display: flex;
    align-items: center;
    background: transparent;
    color: #fff;
    font-weight: 500;
    border: 2px solid$kg_color ;
    color: $kg_color;
    border-radius: 5px;
    transition: 0.4s all;
    cursor: pointer;
    padding: 10px;
    text-align: left;
    width: 100%;
    transition: .2s linear;
    text-transform: capitalize;
    // &:hover {
    //   opacity: .5;
    // }
    &:hover {
      border-color: $tertiary_color;
      color: $tertiary_color;
    }

    // .caret-icon {
    //   margin-left: 10px;
    //   width: 10px;
    //   height: 10px;
    // }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background: black;
    padding: 10px;
    list-style: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 10;

    li {
      margin: 10px 0;

      a {
        color: #fff;
        text-decoration: none;
        transition: .2s linear;

        &:hover {
          color: $kg_color;
        }
      }
    }
  }
}



@media screen and (max-width: 768px) {

  nav {
    background: red;
    display: none;
  }

  .menu-btn.not-user-info {
    display: block;
    margin-left: 20px;
  }

  .header .right-content .search-icon {
    margin-right: -20px;
  }

  :root {

    .sign-in-up.not-user-info {
      display: none;
      background: red;
      .sign-in {
        .sign-in-text {
          font-size: 0.5em;
        }
      }
    }
  }
}

@media screen and (max-width: 490px) {
  .header {
    #logo img {
      width: 100px
      
    }
  }
}

@media screen and (max-width: 250px) {
  .header {
    
    #logo {
      
      img {
        width: 80px
      }
    }
   
  }
}


#logo {
  height: auto;
  a {
    display: flex;
  }
}
