@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,700&display=swap');
@import '../../../global';
// @import '../../components/styles/buttons.scss';
.subscriptions {
  padding-top: 20px;
}
.subscriptions-header {
  font-size: 2rem;
  text-align: center;
  color: #fff;
  margin-bottom: 1.5rem;
  @media only screen and (max-width: 220px) {
    font-size: 1rem;
  }

  @media only screen and (max-width: 300px) {
    font-size: 1.2rem;
  }
}
.pre-btn,
.nxt-btn {
  border: none;
  width: 10vw;
  height: 800px;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #2d0b48 100%);
  cursor: pointer;
  z-index: 8;
  
}

.pre-btn {
  left: 0;
  transform: rotate(180deg);
}

.nxt-btn {
  right: 0;
}

.subscriptions-container {
  height: auto;
  padding: 0 5vw;
  display: flex;
  flex-wrap: wrap;
  // justify-items: center;
  align-items: center;
  justify-content: center !important;
  gap: 30px;
  // grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  color: #fff;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1240px;

  @media only screen and (max-width: 1000px) {
    padding: 0 3vw;
  }

  @media only screen and (max-width: 600px) {
    padding: 0 2vw;
  }

  .subscription-card {
    
    background-color: $kinder_color;
    border-radius: 10px;
    width: 270px;
    padding-bottom: 20px;
    height: 100%;

    @media only screen and (max-width: 300px) {
      width: 80vw;
      // margin-right: 20px;
      // height: 100%;
    }

    @media only screen and (max-width: 200px) {
      width: 90vw;
      // margin-right: 5px;
    }
    &:hover{
        transition: all 1s;
        background-color: #000;
        border: 1px solid #c4bd47;
      
    }
    .plan-name {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 20px;

      @media only screen and (max-width: 300px) {
        margin-bottom: 0px;
      }
      .heading {
        font-size: 1rem !important;
        text-align: center;

        @media only screen and (max-width: 300px) {
          font-size: 0.825rem !important;
        }
      }
    }
    .plan-price {
      margin-bottom: 10px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
     
     
      .price-currency { 
        margin-top: 10px;
        margin-right: 0.5rem;
        font-size: 24px;
      }
      .price {
        height: 100%;
        font-size: 2.825rem;
        font-weight: 700;
        color: #c4bd47;
        @media only screen and (max-width: 300px) {
          font-size: 2.5rem;
        }
      }
    }
    .plan-duration {
      margin-left: 1.5rem;
      
      font-size: 0.825rem;
    }
    
    .plan-benefits {
      font-size: 0.9rem;
      margin-top: 10px;
      margin-bottom: 20px;

      @media only screen and (max-width: 300px) {
        font-size: 0.7rem;
      }

      .sb-item {
        border: 1px #000;
        border-bottom: 1px solid rgba(255, 255, 255, .1);
        width: 80%;
        margin-bottom: 5px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        
        
        .check-mark {
          color:#c4bd47;
          margin-top: 0.3rem;
          margin-right: 0.5rem;
          align-self: flex-start;
        }
      }

      .sb-item.margin-bottom-none {
        border-bottom-style: none;
      }
    }
    .plan-button-container {
      justify-content: center;
      align-items: center;
      display: flex;

      .button {
        background-color: #c4bd47;
        border-radius: 10px;
      }
    }
    .button-wrapper {
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 1rem;
    }

    .icons-container-wrapper {
      justify-content: center;
      align-items: center;
      display: flex;

      .icons-container {
        grid-column-gap: 16px;
        grid-row-gap: 16px;
        grid-template-rows: auto auto;
        grid-template-columns: 1fr 1fr;
        grid-auto-columns: 1fr;
        height: 20px;
        display: flex;
      }
    }
  }
}

.subscriptions-container::-webkit-scrollbar {
  display: none;
}

  
.terms-of-use {
 text-align: center;
  // justify-content: center;
  // align-items: center;
  padding: 7px;
  background-color: $kg_color;
  color: black;
  width: 80%;
  border-radius: 10px;
}