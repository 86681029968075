@import "../../../_global.scss";

.faq {
  background-color: $primary_color;
  padding: 1.625rem 0rem;

  .inside-faq {
    h1 {
      font-size: $headings_font_size;
      font-weight: normal;
      text-align: center;
      color: $tertiary_color;
      // margin-bottom: 0.5rem;
    }
  }
  .faqs-row {
    display: flex !important;
    // width: 50%;
    // flex-direction: row;
    @media only screen and (max-width: 1100px) {
     flex-direction: column;
      }
  }
  .faqs {
    display: flex;
    flex-direction: column;
   
    // padding: 15px;
  }

  .faqs .faq {
    margin: 15px;
    padding: 15px;
    background: $secondary_color;
    border-radius: 10px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    
  }

  .faqs .faq .faq-question {
    position: relative;
    font-size: $normal_font;
    padding-right: 80px;
    color: $kg_color;
    transition: all 0.4s ease;
    @media only screen and (max-width: 400px) {
      font-size: 0.7rem !important;
       }

  }

  .faqs .faq .faq-question::after {
    content: "+";
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    transition: all 0.2s ease;
  }

  .faqs .faq .faq-answer {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s ease;
    color: $tertiary_color;
    font-size: $normal_font;
    @media only screen and (max-width: 400px) {
      font-size: 0.6rem !important;
       }
  }

  .faqs .faq.open .faq-question {
    margin-bottom: 15px;
  }

  .faqs .faq.open .faq-question::after {
    content: "-";
  }

  .faqs .faq.open .faq-answer {
    max-height: 1000px;
    opacity: 1;
  }
}

@media only screen and (max-width: 650px) {
  .inside-faq {
    h1 {
      font-size: $m_headings_font_size !important;
    }
  }

  .faqs .faq .faq-answer {
    font-size: $m_normal_font !important;
  }

  .faqs .faq .faq-question {
    font-size: $m_normal_font !important;
  }
}