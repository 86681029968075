@import '../../../global';
// src/components/styles/unsubscribedUser.scss
.unsubscribed-user {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
    width: 100vw;
    background-color: transparent; // Use a similar background color as in the image
    color: #ffffff;
    text-align: center;
  }
  
  .empty-cart-icon {
    width: 100px; // Adjust the size as needed
    height: 100px; // Adjust the size as needed
    margin-bottom: 20px;
  }
  
  .unsubscribed-message {
    font-size: 18px;
    max-width: 600px;
    margin: 0 20px;
  }
  
  .unsubscribed-button {
    margin-top: 1rem;
    width: 300px;
  }