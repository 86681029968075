@import "../../../_global.scss";

footer {
  background: #090016;
  color: #fff;
  padding: 2rem 20px;
  font-size: 14px;
  position: relative;
  z-index: 9;

  @media only screen and (max-width: 500px) {
    padding: 1rem 15px ;
  }


  .logo img {
    width: 130px;
    // margin-bottom: 10px;
    @media only screen and (max-width: 500px) {
       min-width: 150px;
    }
  }

  .footer-wrapper {
    max-width: 1300px;
    margin: auto;
  }

  .footer-top {
    display: flex;
   flex-direction: column;
    margin-bottom: 25px;
    flex-wrap: wrap;
    align-items: center;

    .col {
      height: auto;
      padding: 0 5vw;
      display: flex !important;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center !important;
      column-gap: 30px;
      row-gap: 10px;
      // color: #fff;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
      max-width: 1440px;
      // display: flex !important;
      // justify-content: space-evenly   ;
      // width: 80%;
      // line-height: 35px;
      // margin-bottom: 20px;
      // flex-wrap: wrap;
      // @media only screen and (max-width: 500px) {
      //   width: 100%;
      //   padding-left: 20px;
      //   padding-right: 20px;
      //   column-gap: 20px;
      // }
     
    
      @media only screen and (max-width: 500px) {
        padding: 0 1vw;
      }
      @media only screen and (max-width: 414px) {
        column-gap: 15px;
        padding: 0 5vw;
      }
     & li {
        font-size: 1.2rem;
        @media only screen and (max-width: 500px) {
          font-size: 1rem;
        }
        @media only screen and (max-width: 360px) {
          font-size: 0.9rem;
        }
        @media only screen and (max-width: 360px) {
          font-size: 0.8rem;
        }
        &:hover {
          color: $kg_color;
        }
      }
    }
    @media only screen and (max-width: 500px) {
      margin-bottom: 10px;
      flex-direction: column;
    }
  }

  .date-footer {
    text-align: center;
    // display: flex !important;
    // align-items: center;
    // justify-content: space-between;
    flex-wrap: wrap;
    
  }

  li {
    list-style: none;
    // @media only screen and (max-width: 500px) {
    //   margin-bottom: 10px;
    
    // }
    a {
      text-decoration: none;
      color: #fff;
      transition: .1s linear;

      &:hover {
        color: $kg_color;
      }
    }
  }

  .links {
    display: flex;
    // flex-wrap: wrap;

    div {
      line-height: 40px;
    }

    div:nth-child(1) {
      margin-right: 50px;
    }
  }
}


