@import "../../../global";

body {
    background: #1A052B;
}

.account-main {
    color: #fff;
    display: flex;
    max-width: 1360px;
    padding: 20px;
    padding-top: 50px;
    margin: auto;

    .active-tab {
        color: $kg_color;
    }

    .tab {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        img {
            width: 28px;
            height: 28px;
            margin-right: 15px;
        }
    }

    .left-nav {
        flex: 1;

        h3,
        p {
            white-space: nowrap;
        }
    }

    .tab-content {
        flex: 5;
        background: #A6A6A8;
        width: 100%;
        padding: 20px;
        border-radius: 3px;

        .profile {
            background: #D9D9D9;
            box-shadow: 9px 5px 4px -1px rgba(0, 0, 0, 0.08);
            border-radius: 20px;
            padding: 20px;
            color: #361F53;
            max-width: 350px;

            small {
                color: #7B7682;
                margin-left: 10px;
            }

            .profile-image {
                background: #361F53;
                border-radius: 15px;
                margin-bottom: 20px;
                width: 100%;
                height: 200px;
            }
        }
    }

    input {
        background: transparent;
        border: none;
        padding: 5px 0;
        border-bottom: 1px solid #361F53;
        width: 100%;
        outline: none;
    }

    .save-btn {
        background: #361F53;
        border-radius: 12px;
        color: #fff;
        padding: 10px 25px;
        margin: 20px auto;
        margin-bottom: 0;
        cursor: pointer;
        transition: 0.4s all;
        &:hover {
            transition: 0.4s all;
            background: #1A052B;
        }
    }

    .flex {
        display: flex;

        input:nth-child(2) {
            margin-left: 10px;
        }
    }

    ul {
        list-style: none;

        li {
            padding: 10px 0;
            cursor: pointer;
            transition: .15s linear;

            &:hover {
                color: $kg_color;
            }
        }
    }
}

.watchlist {
    .watchlist-movie-card {
        margin-bottom: 20px;
        max-width: 800px;

        &>a {
            color: #000;
            text-decoration: none;
        }

        .description {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        .watchlist-movie-content-wrapper {
            display: flex;

            .card-text {
                padding: 10px;

                .name {
                    white-space: nowrap;
                    font-weight: bold;
                    margin-bottom: 10px;
                }
            }


        }

        img {
            width: 150px;
            max-width: 350px;
            border-radius: 15px;
        }
    }
}

@media screen and (max-width:768px) {
    main {
        flex-direction: column;

        .left-nav {
            ul {
                display: flex;
                align-items: center;
                overflow-x: scroll;
                margin-top: -30px;
                margin-bottom: 20px;

                .tab {
                    margin: 0 28px;
                    margin-left: 0;

                    img {
                        width: 20px;
                        margin-right: 5px;
                    }
                }
            }
        }

        .tab-content {
            width: 100%;

            .watchlist {
                width: 100%;

                .watchlist-movie-content-wrapper {
                    flex-direction: column;
                    padding: 10px;
                    margin-top: -10px;
                    margin-bottom: 20px;
                    margin-left: -10px;
                    margin-right: -10px;

                    .card-text {
                        margin-left: -5px;
                    }
                }
            }
        }
    }
}
.support-header {
    color: $kinder_color;
    @media only screen and (max-width: 500px) {
        font-size: 1.1rem;
    }
}

.support-body {
    margin-top: 1rem;
}
.support-row {

    display: flex;
    .support-row-text {
        display: flex;
        flex-direction: column;
       color: black;
        
       h3 {
        font-size: 1rem;

        @media only screen and (max-width: 500px) {
            font-size: 0.9rem;
        }

       }

       p {
        @media only screen and (max-width: 500px) {
            font-size: 0.8rem;
        }
       }
    }
}