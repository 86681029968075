// src/components/ConnectionError.scss
@import "../../../global";

.connection-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
    width: 100vw;
    background-color: #000;
    color: $kg_color;
    text-align: center;
  }
  
  .error-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
  }
  
  .error-text {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  

  .error-button-wrapper {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }

  