// @import '../../../../global';

// .mute-icon {
//     // background: red;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     padding: 0 20px;
//     cursor: pointer;

//     &:hover {
//         opacity: .9;
//     }
// }

// .hero-player-container {
//     height: 100vh;
//     width: 100vw;
//     position: absolute;
//     top: 0;
//     left: 0;
//     display: flex;
//     align-items: center;
//     justify-content: center;

//     img {
//         width: 100%;
//         height: 100%;
//         object-fit: cover;
//     }
// }

// .react-player {
//     width: 100vw;
//     height: 100vh;

//     video {
//         object-fit: cover;
//     }
// }

// .hero {
//     height: 100vh;
//     margin-top: -70px;
//     position: relative;

//     .hero-gradient {
//         background: linear-gradient(to left, #0000, #000000);
//         height: 100vh;
//         width: 100vw;
//         top: 0;
//         left: 0;
//         position: absolute;
//     }

//     .hero-container {
//         margin-top: 70px;
//         height: 100%;
//         width: 100%;
//         display: flex;
//         flex-direction: column;
//         max-width: 1460px;
//         margin: 0 auto;
//         color: #fff;
//         position: relative;
//         z-index: 23;

//         .hero-content-wrapper {
//             height: 100%;
//             display: flex;
//             align-items: center;

//             // justify-content: center;
//             max-width: 1000px;
//             // background: green;

//             .hero-content {
//                 width: 100%;
//                 padding: 20px;
//                 // background: red;
//                 max-width: 500px;
//                 &.landing-content-padding {
//                     padding-left: 3rem;

//                     @media screen and (max-width: 768px)  {
//                         padding-left: 20px;
//                     }
//                 }
//                 h1 {
//                     text-align: left;
//                     // font-family: 'Open Sans';
//                     font-style: normal;
//                     font-weight: 600;
//                     font-size: 3rem;
//                     text-transform: uppercase;
//                     margin-bottom: 30px;
//                 }

//                 .hero-content-description {
//                     line-height: 32px;
//                 }

//                 .hero-content-description,
//                 h1 {
//                     font-family: "-apple-system", BlinkMacSystemFont, "-webkit-system-font", "Malgun Gothic", "Segoe UI", "Helvetica Neue", Helvetica, sans-serif;
//                 }

//                 h3 {
//                     margin-top: -30px;
//                     margin-bottom: 50px;
//                 }

//                 .cast {
//                     margin: 30px 0;
//                 }

//                 .hero-buttons {
//                     display: flex;
//                     margin-top: 30px;
//                     &.landing-dynamic-banner {
//                         justify-content: flex-start;
//                         gap: 10px;

//                         .landing-page-dynamic-btns:not(:first-child) {
//                             background-color: #000000;
//                             color: $tertiary_color;
//                         }
//                     }
//                     .outline-btn:nth-child(2) {
//                         margin-left: 10px;
//                     }
//                 }

//             }
//         }

//         .hero-slider-container {
//             width: 100%;
//             max-height: 300px;

//             .active-hero-slider-item {
//                 filter: grayscale(1);
//             }
//         }

//         .hero-slider-item {
//             height: 170px;
//             width: 100%;
//             cursor: pointer;

//             img {
//                 width: 100%;
//                 height: 100%;
//                 object-fit: cover;
//                 mix-blend-mode: normal;
//                 border-radius: 14px;
//             }
//         }

//         .livetv-hero-slider-item {
//             height: 100px;
//             transform: scale(1.1);
//             display: flex;
//             align-items: center;
//             white-space: nowrap;

//             &>div {
//                 // background: red;
//                 height: 100%;
//                 display: flex;
//                 // align-items: center;
//                 flex-direction: column;
//                 justify-content: space-between;
//                 margin-left: 20px;
//             }
//         }
//     }

//     .livetv-gradient {
//         background: linear-gradient(180deg, rgba(21, 5, 34, 0) 0%, #150522 93.66%);
//     }

//     .afripremiere-hero-gradient {
//         background: linear-gradient(200.32deg, rgba(217, 217, 217, 0) -60.01%, #150522 74.62%);
//     }

//     .genre-year-age-container {
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//         max-width: 270px;
//         margin-bottom: 30px;

//         .age {
//             border: 2px solid #FFFEFF;
//             border-radius: 7px;
//             padding: 0 3px
//         }
//     }

//     .afripremiere-hero-slider-item {
//         width: 440px;
//         height: 145px;
//         background: red;
//         border-radius: 8px;
//         background: linear-gradient(154.58deg, rgba(156, 156, 155, 0.57) 1.98%, rgba(208, 208, 208, 0.1881) 64.22%);
//         mix-blend-mode: screen;
//         filter: blur(0.5px);
//         border-radius: 12px;
//         cursor: pointer;

//         display: flex;
//         align-items: center;

//         &>div {
//             // background: red;
//             padding: 0 20px;
//             color: #fff;
//             width: 70%;
//             display: flex;
//             align-items: center;
//             justify-content: space-between;

//             img {
//                 background: linear-gradient(154.58deg, rgba(156, 156, 155, 0.57) 8.03%, rgba(208, 208, 208, 0.1881) 70.27%);
//                 width: 30px;
//                 height: 30px;
//                 border-radius: 9999px;
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//                 padding: 6px;
//                 padding-left: 6px;
//                 padding-left: 9px;
//             }
//         }

//         img {
//             // width: 40%;
//             width: 30%;
//             height: 100%;
//             object-fit: cover;
//             mix-blend-mode: normal;
//             border-radius: 8px;
//             border-top-right-radius: 0;
//             border-bottom-right-radius: 0;
//         }
//     }

//     .active-afripremiere-hero-slider-item {
//         border: 4px solid #C4BD47;
//     }

//     .afriplaylive-hero-gradient {
//         background: none;
//     }

//     .live-date-wrapper {
//         display: flex;
//         align-items: center;
//         width: 100%;

//         .live-label {
//             color: #fff;
//             width: max-content;
//             padding: 2px 15px;
//             border-radius: 6px;
//             font-weight: 500;
//             background: red;
//             margin-right: -50px;
//         }

//         p {
//             margin-left: 80px;
//         }
//     }

//     .slider-title {
//         font-weight: 500;
//         font-size: 1.2rem;
//         margin-bottom: 15px;
//     }

//     .pay-per-view-btn {
//         background: $kg_color;
//         width: max-content;
//         padding: 8px 14px;
//         border-radius: 8px;
//         color: #150522;
//         font-weight: 500;
//     }
// }

// @media screen and (max-width: 768px) {
//     .hero-slider-container {
//         display: none;
//     }
// }

// .hero-app-links-wrapper {
//     display: flex;
//     padding-left: 3rem;
//     padding-bottom: 0.5rem;
//     justify-content: flex-start;
//     gap: 10px;
//     .hero-app-link {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         a {
//         text-decoration: none;
//         color: $tertiary_color;
//             font-size: 20px;
//             line-height: 20px;
//         }
//         .hero-app-link-img {
//             margin-right: 0.5rem;
//             height: 30px;
//             width: 30px;
//         }

//     }
// }
@import '../../../../global';

.mute-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    cursor: pointer;

    &:hover {
        opacity: .9;
    }
}

.hero-player-container {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    // overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        ;
    }
}

.react-player {
    width: 100vw;
    height: 100vh;

    video {
        object-fit: cover;
    }
}

.hero {
    height: 100vh;
    margin-top: -10px;
    position: relative;

    &.feature-dynamic-banner {
        height: 65vh;
    }

    &.landing-dynamic-banner {
        height: 75vh;
    }

    .hero-gradient {
        background: linear-gradient(to left, #0000, #000000);
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        position: absolute;
    }

    .hero-container {
        margin-top: 70px;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        max-width: 1460px;
        margin: 0 auto;
        color: #fff;
        position: relative;
        z-index: 23;
        
        .hero-content-wrapper {
            height: 100%;
            display: flex;
            align-items: center;
            max-width: 1000px;

            .hero-content {
                width: 100%;
                padding: 20px;
                max-width: 500px;
                @media screen and (max-width: 1200px) {
                    font-size: 1rem;
                }
                @media screen and (max-width: 900px) {
                    font-size: 0.9rem;
                }
                @media screen and (max-width: 600px) {
                    font-size: 0.7rem;
                }
                &.landing-content-padding {
                    padding-left: 3rem;

                    @media screen and (max-width: 1200px) {
                        padding-left: 2rem;
                    }

                    @media screen and (max-width: 992px) {
                        padding-left: 1.5rem;
                    }

                    @media screen and (max-width: 768px) {
                        padding-left: 1rem;
                    }

                    @media screen and (max-width: 480px) {
                        padding-left: 20px;
                    }
                }

                h1 {
                    text-align: left;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 3rem;
                    text-transform: uppercase;
                    margin-bottom: 30px;

                    @media screen and (max-width: 992px) {
                        font-size: 2.5rem;
                    }

                    @media screen and (max-width: 768px) {
                        font-size: 2rem;
                    }

                    @media screen and (max-width: 480px) {
                        font-size: 1.5rem;
                    }
                }

                .hero-content-description {
                    line-height: 32px;

                    @media screen and (max-width: 992px) {
                        line-height: 28px;
                    }

                    @media screen and (max-width: 768px) {
                        line-height: 24px;
                    }

                    @media screen and (max-width: 480px) {
                        line-height: 20px;
                    }
                }

                .hero-content-description,
                h1 {
                    font-family: "-apple-system", BlinkMacSystemFont, "-webkit-system-font", "Malgun Gothic", "Segoe UI", "Helvetica Neue", Helvetica, sans-serif;
                    text-transform: capitalize;
                }

                h3 {
                    margin-top: -30px;
                    margin-bottom: 50px;

                    @media screen and (max-width: 992px) {
                        margin-top: -20px;
                        margin-bottom: 40px;
                    }

                    @media screen and (max-width: 768px) {
                        margin-top: -10px;
                        margin-bottom: 30px;
                    }
                }

                .cast {
                    margin: 30px 0;

                    @media screen and (max-width: 768px) {
                        margin: 20px 0;
                    }
                }

                .hero-buttons {
                    display: flex;
                    margin-top: 30px;

                    @media screen and (max-width: 992px) {
                        margin-top: 20px;
                    }

                    @media screen and (max-width: 768px) {
                        // flex-direction: column;
                        align-items: center;
                    }

                    &.landing-dynamic-banner {
                        justify-content: flex-start;
                        gap: 10px;
                        flex-wrap: wrap;
                        align-items: center;

                        .landing-page-dynamic-btns:not(:first-child) {
                            background-color: #000000;
                            color: $tertiary_color;
                        }

                        .mute-icon {
                            padding: 0;
                            // @media screen and (min-width: 768px) {
                            //     margin-left: auto;
                            // }
                        }


                    }

                    .outline-btn:nth-child(2) {
                        margin-left: 10px;

                        // @media screen and (max-width: 768px) {
                        //     margin-left: 0;
                        //     // margin-top: 10px;
                        // }

                        // @media screen and (max-width: 540px) {
                        //     margin-left: 10px;
                        //     margin-top: 0px;
                        // }
                    }
                }
            }
        }

        .hero-slider-container {
            width: 100%;
            max-height: 300px;

            // @media screen and (max-width: 768px) {
            //     display: none;
            // }
            // @media screen and (max-width: 768px) {
            //     display: block;  // Make sure the slider is displayed on mobile.
            //     max-height: 150px;  // You can adjust the height for mobile responsiveness.
            // }

            .active-hero-slider-item {
                filter: grayscale(1);
            }
        }

        .hero-slider-item {
            height: 170px;
            width: 100%;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                mix-blend-mode: normal;
                border-radius: 14px;
            }
        }

        .livetv-hero-slider-item {
            height: 100px;
            transform: scale(1.1);
            display: flex;
            align-items: center;
            white-space: nowrap;

            &>div {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-left: 20px;
            }
        }
    }

    .livetv-gradient {
        background: linear-gradient(180deg, rgba(21, 5, 34, 0) 0%, #150522 93.66%);
    }

    .afripremiere-hero-gradient {
        background: linear-gradient(200.32deg, rgba(217, 217, 217, 0) -60.01%, #150522 74.62%);
    }

    .genre-year-age-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 270px;
        margin-bottom: 30px;

        .age {
            border: 2px solid #FFFEFF;
            border-radius: 7px;
            padding: 0 3px;
        }
    }

    .afripremiere-hero-slider-item {
        width: 100%;
        height: 145px;
        background: red;
        border-radius: 8px;
        background: linear-gradient(154.58deg, rgba(156, 156, 155, 0.57) 1.98%, rgba(208, 208, 208, 0.1881) 64.22%);
        mix-blend-mode: screen;
        filter: blur(0.5px);
        border-radius: 12px;
        cursor: pointer;
        display: flex;
        align-items: center;
        white-space: nowrap;
        // @media screen and (max-width: 850px) {
        //     height: 100px;
        //     width: 100%;
        //     // flex-direction: column;
        // }

       

        @media screen and (width: 414px) and (height: 896px) {
            // styles here
            height: 200px;

            .text-content {
                font-size: 1.5rem;
            }
        }
        @media screen and (width: 390px) and (height: 844px) {
            // styles here
            height: 210px;

            .text-content {
                font-size: 1.2rem;
            }
        }
        @media screen and (width: 430px) and (height: 932px) {
            // styles here
            height: 250px;

            .text-content {
                font-size: 1.2rem;
            }
        }
        @media screen and (width: 412px) and (height: 915px) {
            // styles here
            height: 250px;

            .text-content {
                font-size: 1.2rem;
                white-space: nowrap;
            }
        }

        @media screen and (width: 768px) and (height: 1024px) {
            // styles here
            height: 300px;
            flex-direction: column;
            .afripremiere-hero-slider-item-img {
            width: 100% !important;
            height: 70% !important;

           }
            .text-content {
                font-size: 1.2rem;
                display: flex !important;
                gap: 30px;
                margin-top: 5px;
                white-space: nowrap;
            }
        }
        @media screen and (width: 820px) and (height: 1180px) {
            // styles here
            height: 300px;
            flex-direction: column;
            .afripremiere-hero-slider-item-img {
            width: 100% !important;
            height: 70% !important;

           }
            .text-content {
                font-size: 0.9rem;
                display: flex !important;
                gap: 20px;
                margin-top: 10px;
                white-space: nowrap;
            }
        }
        @media screen and (width: 1024px) and (height: 1366px) {
            // styles here
            height: 300px;
            flex-direction: column;
            .afripremiere-hero-slider-item-img {
            width: 100% !important;
            height: 70% !important;

           }
            .text-content {
                font-size: 0.9rem;
                display: flex !important;
                gap: 20px;
                margin-top: 10px;
                white-space: nowrap;
            }
        }
        @media screen and (width: 1024px) and (height: 600px) {
            // styles here
            height: 100px;
           
            .text-content {
                font-size: 0.9rem;
                
               
                
                white-space: nowrap;
            }
        }
        @media screen and (width: 320px) and (height: 480px) {
            // styles here
            height: 100px;
           
            .text-content {
                font-size: 0.9rem;
                
               
                
                white-space: nowrap;
            }
        }
        &>div {
            padding: 0 20px;
            color: #fff;
            width: 70%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            img {
                background: linear-gradient(154.58deg, rgba(156, 156, 155, 0.57) 8.03%, rgba(208, 208, 208, 0.1881) 70.27%);
                width: 30px;
                height: 30px;
                border-radius: 9999px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 6px;
                padding-left: 6px;
                padding-left: 9px;
            }
        }

        img {
            width: 30%;
            height: 100%;
            object-fit: cover;
            mix-blend-mode: normal;
            border-radius: 8px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .active-afripremiere-hero-slider-item {
        border: 4px solid #C4BD47;
    }

    .afriplaylive-hero-gradient {
        background: none;
    }

    .live-date-wrapper {
        display: flex;
        align-items: center;
        width: 100%;

        .live-label {
            color: #fff;
            width: max-content;
            padding: 2px 15px;
            border-radius: 6px;
            font-weight: 500;
            background: red;
            margin-right: -50px;
        }

        p {
            margin-left: 80px;
        }
    }

    .slider-title {
        font-weight: 500;
        font-size: 1.2rem;
        margin-bottom: 15px;
    }

    .pay-per-view-btn {
        background: $kg_color;
        width: max-content;
        padding: 8px 14px;
        border-radius: 8px;
        color: #150522;
        font-weight: 500;
    }
}

.hero-app-links-wrapper {
    display: flex;
    padding-left: 3rem;
    padding-bottom: 0.5rem;
    justify-content: flex-start;
    gap: 10px;

    @media screen and (max-width: 768px) {
        padding-left: 1rem;
        // flex-direction: column;
    }

    @media screen and (max-width: 480px) {
        padding-left: 0.5rem;
    }

    .hero-app-link {
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            text-decoration: none;
            color: $tertiary_color;
            font-size: 20px;
            line-height: 20px;

            @media screen and (max-width: 768px) {
                font-size: 18px;
            }

            @media screen and (max-width: 480px) {
                font-size: 16px;
            }
        }

        .hero-app-link-img {
            margin-right: 0.5rem;
            height: 30px;
            width: 30px;

            @media screen and (max-width: 768px) {
                height: 25px;
                width: 25px;
            }

            @media screen and (max-width: 480px) {
                height: 20px;
                width: 20px;
            }
        }
    }
}

.text-content {
    @media screen and (max-width: 700px) {
        font-size: 0.8rem;
    }

    @media screen and (max-width: 480px) {
        font-size: 0.7rem;
    }
}